import type { GetStaticPropsContext } from 'next'

import AuthenticationProvider from '../providers/AuthenticationProvider/AuthenticationProvider'

import { getTranslationProps } from '../helpers/i18n'

import NotFound from '../application/NotFound/NotFound'

export default function Page404() {
  return (
    <AuthenticationProvider>
      <NotFound />
    </AuthenticationProvider>
  )
}

export async function getStaticProps(context: GetStaticPropsContext) {
  return {
    props: {
      ...(await getTranslationProps(context)),
    },
  }
}
